<template>
  <div class="home-box">
    <!-- 查询区域 -->

    <!-- table区域-begin -->
    <div class="form-box">
      <el-form ref="form" :model="form" :rules="rules" label-width="150px" v-loading="loading">
        <!-- <div>
          <div class="el-dialog-header-box">
            <div class="dialog-title-block"></div>
            <span class="dialog-title-text">银行设置</span>
          </div>
          <el-divider />
        </div>
        <el-form-item label="银行卡号" prop="code">
          <el-input v-model="form.code" placeholder="银行卡号"></el-input>
        </el-form-item>
        <el-form-item label="开户行姓名" prop="bankname">
          <el-input v-model="form.bankname" placeholder="开户行姓名"></el-input>
        </el-form-item>
        <el-form-item label="开户行名称" prop="bankname">
          <el-input v-model="form.bankname" placeholder="开户行名称"></el-input>
        </el-form-item>
        <el-form-item label="开户行支行名称" prop="bankaddress">
          <el-input
            v-model="form.bankaddress"
            placeholder="开户行支行名称"
          ></el-input>
        </el-form-item> -->

        <div>
          <div class="el-dialog-header-box">
            <div class="dialog-title-block"></div>
            <span class="dialog-title-text">支付宝设置</span>
          </div>
          <el-divider />
        </div>
        <el-form-item label="支付宝账号或手机号" prop="code">
          <el-input v-model="form.code" placeholder="支付宝账号或手机号"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="username">
          <el-input v-model="form.username" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label-width="50%">
          <el-button type="primary" @click="onSubmit('form')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- table区域-end -->

    <!-- 分页区域-begin -->
    <!-- 分页区域-end -->
  </div>
</template>
  
  <script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default({
  name: "cashOutAccount",
  data() {
    return {
      form: {
        bankname: "",
        bankaddress: "",
        code: "",
        username: "",
      },
      rules: {
        code: [
          { required: true, message: '请输入支付宝账号或手机号', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
      },
      loading: false,
      dialogVisible: false,
      disabled: false,

    };
  },
  computed: {
    ...mapState(["user", "setting"]),
  },
  mounted() {
    let user = JSON.parse(localStorage.user);
    this.token = user.token;
    // this.getautoshow()
    // this.getInfo()
    // this.getechart();
    this.getAccount()
    // setTimeout(() => {
    //   this.loading = false;
    // }, 1000);
  },
  methods: {
    ...mapMutations(["setuser", "setsetting"]),
    getechart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "核销券数",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // prettier-ignore
          data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
          axisPointer: {
            snap: true,
          },
        },
        series: [
          {
            smooth: true,
            data: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              12,
              33,
              222,
              333,
              123,
              233,
              112,
              212,
              444,
              333,
              1,
              11,
              22,
            ],
            type: "line",
          },
        ],
      });
    },
    getInfo() {
      axios
        .post("http://shunyu.jnwww.top/admin.php?action=setting&method=store", {
          is_get: 1,
          token: this.token,
        })
        .then((res) => {
          console.log(res);
          if (res.data.msg == "登录已过期") {
            localStorage.removeItem("user");
            this.$router.replace("/");
            return;
          }
          let data = {
            store: res.data.data.store_name,
            logo: res.data.data.store_logo,
          };
          this.setsetting(data);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getautoshow() {
      axios
        .get(
          "http://shunyu.jnwww.top/admin.php?action=autoshow&method=ex_list",
          {
            params: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.msg == "登录已过期") {
            localStorage.removeItem("user");
            this.$router.replace("/");
            return;
          }
          res.data.data.data.forEach((item) => {
            this.options.push({
              label: item.exhibition_title,
              value: item.exhibition_id,
            });
            this.value = res.data.data.data[0].exhibition_id;
            this.getList();
          });
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getList() {
      axios
        .post(
          "http://shunyu.jnwww.top/admin.php?action=autoshow&method=tk_list",
          {
            exhibition_ids: this.value,
            begin_time: this.date[0],
            end_time: this.date[1],
            page_idx: this.page,
            page_size: 10,
            token: this.token,
          }
        )
        .then((res) => {
          this.tableData = res.data.data.data;
          if (res.data.data.data.length == 0) {
            this.empty = this.page;
            this.$message.error("已无数据");
          } else {
            this.empty = 10000000000000000;
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      console.log("file.type", file.type);
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 jpg、png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove(file) {
      console.log(file);
      this.form.imageUrl = "";
    },
    handlePictureCardPreview(file) {
      this.form.imageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    // 提交提现账号
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$api.referAccount({...this.form}).then(
              res => {
                if(res.code == 'success'){
                  this.$message.success(res.msg)
                }else{
                  this.$message.error(res.msg)
                }
              }
            ).catch(err=>{
              this.$message.error(err.msg)
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    // 获取提现账号
	  getAccount() {
      this.loading = true
      this.$api.getAccount({}).then(
        res => {
          if(res.code == 'success'){
            this.form = res.data||{}
          }else{
            this.$message.error(res.msg)
          }
          this.loading = false
        }
      ).catch(err=>{
        this.$message.error(err.msg)
        this.loading = false
      })
    },
  },
});
</script>
  
  <style scoped lang="less">
@import "../assets/less/easyElement.less";
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #949494;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0 0 24px;
}
</style>
  